import './spinner.css';

function Spinner () {
    return (
        <div className="spinner">
            <div /><div /><div /><div /><div /><div /><div /><div /><div />
        </div>
    );
}

export default Spinner;
