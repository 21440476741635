import "./svg-sprites.css";

export default function SvgSprites() {
    return (
        <svg version="1.1" id="svg-sprites" viewBox="0 0 1 1">
            <symbol id="icon-enter-fullscreen">
                <rect className="fill" x="8" y="8" width="12" height="4" />
                <rect className="fill" x="8" y="8" width="4" height="12" />
                <rect className="fill" x="28" y="8" width="12" height="4" />
                <rect className="fill" x="36" y="8" width="4" height="12" />
                <rect className="fill" x="8" y="28" width="4" height="12" />
                <rect className="fill" x="8" y="36" width="12" height="4" />
                <rect className="fill" x="28" y="36" width="12" height="4" />
                <rect className="fill" x="36" y="28" width="4" height="12" />
            </symbol>
            <symbol id="icon-exit-fullscreen">
                <rect className="fill" x="8"  y="16" width="12" height="4" />
                <rect className="fill" x="16" y="8"  width="4" height="12" />
                <rect className="fill" x="28" y="16" width="12" height="4" />
                <rect className="fill" x="28" y="8"  width="4" height="12" />
                <rect className="fill" x="16" y="28" width="4" height="12" />
                <rect className="fill" x="8"  y="28" width="12" height="4" />
                <rect className="fill" x="28" y="28" width="12" height="4" />
                <rect className="fill" x="28" y="28" width="4" height="12" />
            </symbol>
            <symbol id="icon-hint">
                <path
                    className="fill"
                    d="m 23.865234,4.0683594 C 15.885331,4.0676743 9.3697539,10.582581 9.3691406,18.5625 v 0.002 c 0.00423,5.138893 2.8259154,9.739013 7.1875004,12.330078 a 0.50005,0.50005 0 0 1 0.24414,0.429688 v 6.902343 c -3.1e-5,1.103075 0.885209,1.988314 1.988281,1.988282 h 1.705079 a 0.50005,0.50005 0 0 1 0.482421,0.363281 c 0.365871,1.294608 1.541405,2.184869 2.886719,2.185547 1.344662,-0.0015 2.519074,-0.891566 2.884766,-2.185547 a 0.50005,0.50005 0 0 1 0.482422,-0.363281 h 1.710937 c 1.103073,3.2e-5 1.988312,-0.885208 1.988282,-1.988282 v -6.90625 a 0.50005,0.50005 0 0 1 0.24414,-0.429687 c 4.35942,-2.590069 7.179147,-7.187715 7.185547,-12.324219 v -0.004 C 38.358762,10.583411 31.844231,4.0689725 23.865234,4.0683594 Z m 0,3.9726562 c 5.829823,4.48e-4 10.518702,4.6883604 10.521485,10.5175784 a 0.50005,0.50005 0 0 0 0,0.002 c -0.0051,4.158957 -2.451105,7.915246 -6.251953,9.603515 -0.718646,0.319463 -1.179885,1.029954 -1.179688,1.816407 v 3.363281 a 0.50005,0.50005 0 0 1 -0.5,0.5 h -5.179687 a 0.50005,0.50005 0 0 1 -0.5,-0.5 v -3.357422 c -8.9e-5,-0.786839 -0.462357,-1.497431 -1.181641,-1.816406 -3.803224,-1.688517 -6.248553,-5.448278 -6.251953,-9.609375 0.0017,-5.830969 4.69221,-10.5200321 10.523437,-10.5195314 z m -0.550781,2.4335934 c -0.04217,0.0012 -0.08302,0.0036 -0.125,0.0078 -3.273744,0.277146 -6.057237,2.54042 -6.994141,5.689454 -0.687554,2.312411 2.781194,3.343661 3.46875,1.03125 0.515751,-1.733497 2.027931,-2.964625 3.830079,-3.117187 2.378775,-0.146222 2.201881,-3.701246 -0.179688,-3.611329 z"
                />
            </symbol>
            <symbol id="icon-settings">
                <path
                    className="fill"
                    d="m21.644 4.836-.94 5.404A14.166 14.166 0 0 0 16.6 11.94l-4.486-3.156-1.666 1.666-1.666 1.666 3.163 4.496a14.166 14.166 0 0 0-1.693 4.09l-5.417.943v4.712l5.418.943a14.166 14.166 0 0 0 1.69 4.092l-3.161 4.494 1.666 1.666 1.666 1.666 4.496-3.163a14.166 14.166 0 0 0 4.091 1.7l.942 5.41h4.712l.94-5.404a14.166 14.166 0 0 0 4.11-1.693l4.479 3.15 1.666-1.666 1.666-1.666-3.151-4.48a14.166 14.166 0 0 0 1.707-4.11l5.39-.939v-4.712l-5.389-.938a14.166 14.166 0 0 0-1.702-4.12l3.145-4.47-1.666-1.667-1.666-1.666-4.48 3.151a14.166 14.166 0 0 0-4.11-1.7l-.939-5.398H24Zm2.369 12.486a6.675 6.675 0 0 1 6.675 6.675 6.675 6.675 0 0 1-6.675 6.675 6.675 6.675 0 0 1-6.675-6.675 6.675 6.675 0 0 1 6.675-6.675z"
                />
            </symbol>
            <symbol id="icon-menu">
                <rect className="fill" x="12" y="12" width="24" height="4" />
                <rect className="fill" x="12" y="22" width="24" height="4" />
                <rect className="fill" x="12" y="32" width="24" height="4" />
            </symbol>
            <symbol id="icon-pause">
                <rect className="fill" x="15" y="12" width="6" height="24" />
                <rect className="fill" x="27" y="12" width="6" height="24" />
            </symbol>
            <symbol id="modal-icon-ok">
                <path
                    className="icon"
                    d="M53.5 3.11a47 47 0 1 0-6.9 93.76A47 47 0 0 0 53.5 3.1Zm11.93 20.13.12.08 16.4 11.17c.16.11.2.3.12.45L61.41 65.3 50.28 81.7a.34.34 0 0 1-.5.09l-16.4-11.17L13.91 57.4a.34.34 0 0 1-.11-.5l11.17-16.4a.34.34 0 0 1 .45-.08l19.22 13.06L65.1 23.4a.34.34 0 0 1 .38-.12z"
                />
            </symbol>
            <symbol id="modal-icon-warning">
                <path
                    className="icon"
                    d="M50.22 4.53a3.34 3.34 0 0 0-3.04 1.67L.83 86.4a3.34 3.34 0 0 0 2.93 5.05h92.62a3.34 3.34 0 0 0 2.88-5.05L52.96 6.24a3.34 3.34 0 0 0-2.74-1.71ZM44.4 30.59h11.32c.11 0 .15.08.15.15v35.03c0 .07-.04.15-.15.15H44.4a.15.15 0 0 1-.2-.15V30.74c0-.07.08-.15.2-.15zm5.66 38.98a6.27 6.27 0 0 1 6.27 6.27 6.27 6.27 0 0 1-6.27 6.26 6.27 6.27 0 0 1-6.27-6.26 6.27 6.27 0 0 1 6.27-6.27z"
                />
            </symbol>
            <symbol id="modal-icon-error">
                <path
                    className="icon"
                    d="M16.8 16.75a47 47 0 0 0 0 66.48 47 47 0 0 0 66.49 0 47 47 0 0 0 0-66.48 47 47 0 0 0-66.48 0zm6.12 19.64 14.36-14.32a.38.38 0 0 1 .5 0L50.8 35.06l12.99-13a.38.38 0 0 1 .5 0L78.64 36.4a.38.38 0 0 1 0 .5L65.62 49.9l13.03 13.03a.38.38 0 0 1 0 .5L64.3 77.76a.38.38 0 0 1-.49 0l-13-13.03-13.02 13.03a.38.38 0 0 1-.5 0L22.92 63.44a.38.38 0 0 1 0-.5l13.03-13.03-13.03-13.03a.38.38 0 0 1 0-.5z"
                />
            </symbol>
            <symbol id="site-domain">
                <path
                    className="fill"
                    d="M111.818 32.04V49.54a12.184 12.184 0 0 0-3.525-2.361c-1.315-.583-2.9-.874-4.755-.874-2.178 0-4.14.442-5.887
1.326a13.255 13.255 0 0 0-4.496 3.623c-1.25 1.553-2.21 3.385-2.878 5.499-.669 2.113-1.003 4.399-1.003 6.857 0
2.717.291 5.132.873 7.245.604 2.113 1.445 3.892 2.523 5.337 1.1 1.423 2.404 2.512 3.914 3.267 1.51.755 3.17
1.132 4.98 1.132 2.308 0 4.324-.474 6.05-1.423a16.663 16.663 0 0 0 4.625-3.72l.68 3.202c.28.97.948 1.456 2.004
1.456h4.885V32.04zm50.206 0v48.065h7.99V64.903h1.81c.648 0 1.133.075 1.456.226.324.151.636.475.938.97l8.475
12.583c.302.539.679.916 1.132 1.132.452.194.992.291 1.617.291h7.213l-11.062-16.108c-.323-.496-.668-.96-1.035
-1.39a5.963 5.963 0 0 0-1.197-1.133c.863-.517 1.66-1.261 2.394-2.232l10.318-12.323h-7.31c-.669 0-1.219.118
-1.65.356-.41.237-.787.582-1.132 1.035l-8.248 10.188c-.323.432-.647.723-.97.874-.302.129-.723.194-1.262.194
h-1.488V32.04zm170.722 0v48.065h7.99v-24c1.078-1.078 2.22-1.93 3.428-2.555a8.319 8.319 0 0 1 3.882-.939c1.897
0 3.31.561 4.237 1.683.927 1.1 1.39 2.663 1.39 4.69v21.121h7.99V58.984c0-1.855-.248-3.548-.744-5.079-.474-1.552
-1.186-2.878-2.135-3.978-.927-1.122-2.091-1.984-3.493-2.588-1.402-.625-3.008-.938-4.82-.938-2.091 0-3.913.378
-5.466 1.132a17.345 17.345 0 0 0-4.27 2.944V32.04zm-295.997.776c-2.459 0-4.636.378-6.534 1.132-1.897.755-3.493
1.758-4.787 3.008a13.067 13.067 0 0 0-2.976 4.302 12.925 12.925 0 0 0-1.002 4.982c0 2.134.301 3.935.905 5.401.626
1.445 1.445 2.652 2.458 3.623a12.785 12.785 0 0 0 3.461 2.361 34.029 34.029 0 0 0 3.947 1.552c1.337.432 2.652.83
3.946 1.197 1.294.367 2.447.82 3.46 1.359 1.014.517 1.823 1.164 2.427 1.94.625.777.937 1.78.937 3.009 0 2.2-.679
3.892-2.037 5.078-1.359 1.164-3.278 1.747-5.758 1.747-1.617 0-3.008-.216-4.172-.647-1.143-.453-2.146-.95-3.008
-1.488a59.94 59.94 0 0 1-2.232-1.456c-.625-.453-1.208-.679-1.747-.679a2.04 2.04 0 0 0-1.1.323 2.554 2.554 0 0 0
-.84.744l-2.556 4.173c.906.927 1.941 1.768 3.106 2.523a22.53 22.53 0 0 0 3.72 1.94c1.336.54 2.727.95 4.172 1.23
1.444.302 2.91.452 4.399.452 2.587 0 4.884-.388 6.89-1.164 2.004-.798 3.697-1.876 5.077-3.234a13.594 13.594 0 0
0 3.17-4.82c.733-1.854 1.1-3.838 1.1-5.951 0-1.941-.313-3.59-.938-4.95-.604-1.358-1.413-2.5-2.426-3.428a13.183
13.183 0 0 0-3.46-2.329 33.42 33.42 0 0 0-3.98-1.617 143.272 143.272 0 0 0-3.978-1.326c-1.294-.41-2.447-.873
-3.46-1.39-1.014-.518-1.834-1.144-2.459-1.877-.604-.733-.906-1.65-.906-2.75 0-.862.151-1.649.453-2.36a4.891
4.891 0 0 1 1.359-1.877c.625-.517 1.39-.916 2.296-1.196.906-.302 1.962-.453 3.17-.453 1.337 0 2.49.172
3.46.517.993.324 1.855.69 2.588 1.1.734.41 1.359.787 1.876 1.132.54.324 1.035.486 1.488.486.432 0 .787-.098
1.068-.292.302-.215.582-.55.84-1.002l2.168-4.108c-1.639-1.553-3.623-2.76-5.952-3.623-2.328-.862-4.873-1.294
-7.633-1.294zm197.165.518v46.77l29.5.001v-6.954h-20.767V59.92h16.367v-6.695h-16.367v-12.97h20.766v-6.922zm
-92.912 12.898c-2.505 0-4.78.403-6.827 1.208-2.047.806-3.8 1.95-5.26 3.43-1.437 1.481-2.559 3.289-3.364
5.423-.784 2.134-1.176 4.519-1.176 7.154 0 2.657.392 5.052 1.176 7.186.805 2.134 1.927 3.953 3.364 5.455
1.46 1.503 3.213 2.657 5.26 3.463 2.047.806 4.322 1.209 6.827 1.209 2.482 0 4.736-.403 6.762-1.209 2.047
-.806 3.789-1.96 5.226-3.463 1.46-1.502 2.58-3.32 3.365-5.455.784-2.134 1.176-4.53 1.176-7.186 0-2.635
-.392-5.02-1.176-7.154-.784-2.134-1.906-3.942-3.365-5.423-1.437-1.48-3.18-2.624-5.226-3.43-2.026-.805-4.28
-1.208-6.762-1.208zm240.303.072c-5.37 0-9.963 1.747-13.78 5.24l1.424 2.555c.237.389.55.734.938
1.035.388.28.862.421 1.423.421.647 0 1.23-.151 1.747-.453.539-.302 1.132-.636 1.779-1.003.646-.388 1.39-.733
2.231-1.035.863-.301 1.941-.453 3.235-.453 1.79 0 3.159.54 4.108 1.618.948 1.056 1.423 2.695 1.423 4.916
v1.941c-2.523.065-4.766.259-6.728.582-1.962.302-3.687.701-5.175 1.197-1.466.496-2.706 1.078-3.72 1.747
-1.013.668-1.843 1.38-2.49 2.134-.626.755-1.078 1.553-1.359 2.394-.28.82-.42 1.65-.42 2.49 0 1.489.237
2.793.711 3.914.475 1.122 1.143 2.06 2.006 2.814.862.755 1.886 1.326 3.072 1.715 1.186.366 2.48.55 3.882.55
1.186 0 2.264-.108 3.234-.324.992-.194 1.92-.485 2.782-.873a15.114 15.114 0 0 0 2.49-1.423 31.517 31.517 0
0 0 2.459-2.006l.711 2.394c.238.69.561 1.153.97 1.39.41.216.993.324 1.748.324h3.622v-20.96c0-1.876-.28-3.6
-.84-5.175-.54-1.574-1.338-2.922-2.394-4.043-1.057-1.143-2.351-2.027-3.882-2.652-1.531-.647-3.267-.97-5.207
-.97zm67.053.033c-1.898 0-3.656.247-5.273.743-1.595.496-2.986 1.219-4.172 2.168a10.015 10.015 0 0 0-2.75
3.396c-.647 1.315-.97 2.792-.97 4.431 0 2.027.475 3.796 1.423 5.305.97 1.51 2.297 2.717 3.979 3.622a8.883
8.883 0 0 0-1.909 1.262c-.539.453-.991.938-1.358 1.455a7.34 7.34 0 0 0-.809 1.585 4.79 4.79 0 0 0-.258
1.52c0 1.23.258 2.222.776 2.976.517.755 1.218 1.359 2.102 1.812-1.638.625-2.933 1.466-3.881 2.522-.928
1.036-1.391 2.33-1.391 3.882 0 1.143.302 2.243.906 3.3.603 1.056 1.52 1.983 2.75 2.781 1.228.82 2.77 1.466
4.624 1.94 1.855.497 4.033.744 6.534.744 2.545 0 4.809-.334 6.793-1.002 2.005-.647 3.687-1.51 5.046-2.588
1.38-1.078 2.425-2.307 3.137-3.687.733-1.38 1.1-2.803 1.1-4.27 0-1.574-.313-2.857-.938-3.849a6.62 6.62 0
0 0-2.426-2.393c-1.014-.583-2.167-1.003-3.461-1.262a29.963 29.963 0 0 0-3.979-.582 63.727 63.727 0 0 0
-3.978-.194 37.433 37.433 0 0 1-3.461-.259c-1.014-.13-1.833-.345-2.458-.647-.604-.323-.906-.82-.906-1.488
0-.776.517-1.477 1.552-2.102.583.108 1.176.194 1.78.259.603.065 1.229.097 1.876.097 1.833 0 3.536-.248
5.11-.744 1.596-.518 2.976-1.24 4.14-2.167a9.762 9.762 0 0 0 2.717-3.364c.669-1.337 1.003-2.825 1.003
-4.464 0-1.358-.226-2.61-.68-3.752l2.976-.55c1.143-.215 1.715-.798 1.715-1.746V48.05h-9.542a13.237 13.237
0 0 0-3.429-1.262 17.053 17.053 0 0 0-4.01-.452zm-132.49.064c-2.61 0-4.917.432-6.923 1.294-2.005.863-3.687
2.06-5.045 3.59-1.359 1.51-2.394 3.31-3.106 5.402-.69 2.07-1.035 4.324-1.035 6.76 0 2.717.388 5.143 1.165
7.278.798 2.113 1.876 3.903 3.234 5.37a13.864 13.864 0 0 0 4.755 3.363c1.812.755 3.741 1.132 5.79 1.132
1.164 0 2.329-.086 3.493-.259a16.659 16.659 0 0 0 3.429-.873c1.12-.431 2.2-.992 3.234-1.682a14.876 14.876
0 0 0 2.879-2.62l-2.297-2.911c-.323-.496-.808-.744-1.455-.744-.496 0-.949.162-1.358.485-.389.302-.852.647
-1.392 1.035-.539.367-1.207.712-2.005 1.035-.776.302-1.8.453-3.073.453-1.272 0-2.415-.237-3.428-.711a7.245
7.245 0 0 1-2.588-2.135c-.711-.949-1.261-2.113-1.65-3.493-.366-1.38-.55-2.954-.55-4.723 0-1.703.173
-3.224.518-4.56.345-1.359.863-2.512 1.553-3.461a6.98 6.98 0 0 1 2.652-2.167c1.057-.518 2.286-.777 3.687
-.777 1.1 0 2.016.13 2.75.388.754.238 1.402.518 1.94.841.54.302 1.003.582 1.391.841.389.237.787.356
1.197.356.431 0 .755-.086.97-.259a3.69 3.69 0 0 0 .712-.711l2.103-2.911a15.872 15.872 0 0 0-5.046-3.43c
-1.855-.797-4.022-1.196-6.502-1.196zm102.05 0c-1.121 0-2.156.119-3.105.356-.949.216-1.844.528-2.685.938
-.819.388-1.606.874-2.36 1.456a21.092 21.092 0 0 0-2.103 1.843l-.55-2.62c-.324-.97-1.003-1.455-2.038-1.455
h-4.884v33.186h7.99v-24c1.077-1.078 2.22-1.93 3.428-2.555a8.319 8.319 0 0 1 3.881-.939c1.898 0 3.31.561
4.238 1.683.927 1.1 1.39 2.663 1.39 4.69v21.121h7.99V58.984c0-1.855-.248-3.548-.744-5.079-.475-1.552-1.186
-2.878-2.135-3.978-.927-1.121-2.092-1.984-3.493-2.588-1.402-.625-3.008-.938-4.82-.938zm65.448 0c-2.48 0
-4.712.432-6.696 1.294-1.962.863-3.633 2.038-5.013 3.526-1.359 1.466-2.405 3.202-3.138 5.207-.733 1.984
-1.1 4.108-1.1 6.372 0 2.911.421 5.477 1.262 7.698.841 2.2 1.995 4.054 3.46 5.564a14.697 14.697 0 0 0 5.176
3.396c1.984.755 4.13 1.132 6.437 1.132 1.164 0 2.361-.086 3.59-.259a19.2 19.2 0 0 0 3.655-.873 17.69 17.69
0 0 0 3.461-1.682 13.67 13.67 0 0 0 2.976-2.62l-2.297-2.911c-.323-.496-.82-.744-1.488-.744-.496 0-1.013.151
-1.552.453-.518.302-1.132.636-1.844 1.003-.69.366-1.51.7-2.458 1.003-.927.301-2.038.452-3.332.452-1.293 0
-2.48-.194-3.558-.582a7.644 7.644 0 0 1-2.749-1.811c-.776-.82-1.401-1.844-1.876-3.073-.474-1.25-.776-2.728
-.906-4.431h20.54c.733 0 1.229-.205 1.487-.615.26-.431.389-1.25.389-2.458 0-2.394-.367-4.518-1.1-6.372-.712
-1.876-1.704-3.45-2.976-4.723-1.272-1.293-2.792-2.274-4.56-2.943-1.769-.668-3.699-1.003-5.79-1.003zm46.744
0c-2.61 0-4.917.432-6.922 1.294-2.006.863-3.687 2.06-5.046 3.59-1.358 1.51-2.393 3.31-3.105 5.402-.69 2.07
-1.035 4.324-1.035 6.76 0 2.717.388 5.143 1.164 7.278.798 2.113 1.876 3.903 3.235 5.37a13.864 13.864 0 0 0
4.755 3.363c1.811.755 3.74 1.132 5.79 1.132 1.164 0 2.328-.086 3.493-.259a16.659 16.659 0 0 0 3.428-.873
c1.122-.431 2.2-.992 3.235-1.682a14.876 14.876 0 0 0 2.879-2.62l-2.297-2.911c-.323-.496-.809-.744-1.455
-.744-.496 0-.95.162-1.359.485-.388.302-.852.647-1.39 1.035-.54.367-1.208.712-2.006 1.035-.776.302-1.8.453
-3.073.453-1.272 0-2.415-.237-3.429-.711a7.245 7.245 0 0 1-2.587-2.135c-.712-.949-1.262-2.113-1.65-3.493
-.366-1.38-.55-2.954-.55-4.723 0-1.703.173-3.224.518-4.56.345-1.359.862-2.512 1.552-3.461a6.98 6.98 0 0 1
2.653-2.167c1.056-.518 2.286-.777 3.687-.777 1.1 0 2.016.13 2.75.388.754.238 1.401.518 1.94.841.54.302
1.003.582 1.391.841.388.237.787.356 1.197.356.431 0 .755-.086.97-.259a3.69 3.69 0 0 0 .712-.711l2.102
-2.911a15.872 15.872 0 0 0-5.046-3.43c-1.854-.797-4.021-1.196-6.501-1.196zm29.973 0c-2.48 0-4.733.4-6.76
1.197-2.027.798-3.763 1.93-5.208 3.396-1.423 1.467-2.534 3.256-3.331 5.37-.777 2.113-1.165 4.474-1.165
7.083 0 2.631.388 5.003 1.165 7.116.797 2.114 1.908 3.914 3.331 5.402 1.445 1.488 3.18 2.63 5.208 3.428
2.027.798 4.28 1.197 6.76 1.197 2.458 0 4.69-.399 6.695-1.197 2.027-.797 3.752-1.94 5.175-3.428 1.445
-1.488 2.556-3.288 3.332-5.402.776-2.113 1.164-4.485 1.164-7.116 0-2.609-.388-4.97-1.164-7.083-.776
-2.114-1.887-3.903-3.332-5.37-1.423-1.466-3.148-2.598-5.175-3.396-2.005-.798-4.237-1.197-6.695-1.197
zm37.39 0c-.993 0-1.91.119-2.75.356-.82.216-1.596.518-2.329.906-.712.388-1.39.851-2.038 1.39a20.07
20.07 0 0 0-1.811 1.78l-.518-2.459c-.323-.97-1.002-1.455-2.037-1.455h-4.884v33.186h7.989V55.814c.862
-.992 1.79-1.769 2.782-2.33a6.45 6.45 0 0 1 3.266-.873c1.725 0 3.03.507 3.914 1.52.906 1.014 1.359
2.632 1.359 4.853v21.121h8.021V58.984c0-1.035.151-1.952.453-2.75.302-.798.712-1.455 1.23-1.973a5.53
5.53 0 0 1 1.843-1.229 6.209 6.209 0 0 1 2.296-.42c1.79 0 3.149.539 4.076 1.617.927 1.056 1.39 2.642
1.39 4.755v21.121h7.99V58.984c0-2.006-.259-3.785-.776-5.337-.496-1.553-1.23-2.868-2.2-3.946-.97-1.079
-2.167-1.898-3.59-2.459-1.423-.56-3.051-.84-4.884-.84-1.057 0-2.113.118-3.17.355a11.802 11.802 0 0 0
-2.944 1.1 10.407 10.407 0 0 0-2.555 1.94c-.776.777-1.423 1.715-1.94 2.814-.669-1.876-1.683-3.374
-3.041-4.495-1.337-1.143-3.051-1.715-5.143-1.715zm-541.884.518v21.089c0 1.854.237 3.558.711 5.11.496
1.531 1.219 2.858 2.168 3.979a9.818 9.818 0 0 0 3.493 2.62c1.402.604 3.008.905 4.82.905 2.22 0 4.15
-.42 5.79-1.261a18.156 18.156 0 0 0 4.495-3.364l.55 2.652c.28.97.949 1.456 2.005 1.456h4.884V46.919
h-7.989v24c-1.078 1.078-2.22 1.93-3.428 2.555a8.56 8.56 0 0 1-3.882.906c-1.876 0-3.288-.55-4.237-1.65
-.927-1.121-1.39-2.695-1.39-4.722v-21.09zm140.731 0v21.089c0 1.854.237 3.558.712 5.11.496 1.531 1.218
2.858 2.167 3.979a9.818 9.818 0 0 0 3.493 2.62c1.402.604 3.008.905 4.82.905 2.22 0 4.15-.42 5.79-1.261
a18.156 18.156 0 0 0 4.495-3.364l.55 2.652c.28.97.949 1.456 2.005 1.456h4.885V46.919h-7.99v24c-1.078
1.078-2.22 1.93-3.428 2.555a8.56 8.56 0 0 1-3.882.906c-1.876 0-3.288-.55-4.237-1.65-.927-1.121-1.39
-2.695-1.39-4.722v-21.09zm70.18 0 10.933 16.075-11.386 17.111h7.149c.517 0 .948-.15 1.293-.453.345
-.323.636-.668.873-1.035l6.211-9.607c.172-.28.323-.582.453-.905.15-.324.28-.669.388-1.035l7.019 11.547
c.237.388.528.733.873 1.035.367.302.841.453 1.423.453h7.698l-11.353-17.466 10.9-15.72h-7.342c-.755 0
-1.37.399-1.844 1.197l-5.595 8.733a10.294 10.294 0 0 0-1.003 2.167l-6.954-11.062c-.216-.367-.464-.625
-.744-.776-.28-.173-.712-.26-1.294-.26zm181.858 5.013c1.962 0 3.428.486 4.398 1.456.992.949 1.488
2.242 1.488 3.881 0 .798-.129 1.531-.388 2.2a4.346 4.346 0 0 1-1.1 1.714c-.474.474-1.078.852-1.81
1.132-.734.259-1.596.388-2.588.388-.97 0-1.833-.13-2.588-.388-.733-.28-1.348-.658-1.844-1.132a5.226
5.226 0 0 1-1.1-1.747 6.436 6.436 0 0 1-.355-2.167c0-1.639.485-2.932 1.455-3.881.992-.97 2.47-1.456
4.432-1.456zm35.17.194c1.185 0 2.22.205 3.105.615.905.388 1.65.927 2.231 1.617a6.734 6.734 0 0 1
1.294 2.426c.302.906.453 1.876.453 2.911h-15.073c.367-2.415 1.208-4.28 2.523-5.596 1.337-1.315 3.16
-1.973 5.466-1.973zm-378.114.26c1.23 0 2.372.226 3.429.678 1.078.453 2.07 1.23 2.975 2.33v14.878c
-1.035 1.272-2.135 2.254-3.299 2.944-1.164.69-2.577 1.035-4.237 1.035-1.035 0-1.973-.184-2.814-.55
-.841-.388-1.553-1.003-2.135-1.844-.582-.863-1.035-1.962-1.358-3.3-.302-1.358-.453-3.007-.453-4.948
0-1.92.183-3.58.55-4.981.366-1.423.895-2.588 1.585-3.494.69-.927 1.52-1.617 2.49-2.07.97-.452 2.06
-.679 3.267-.679zm35.588.02c2.787 0 4.845.947 6.174 2.842 1.35 1.894 2.025 4.65 2.025 8.264 0 3.615
-.675 6.359-2.025 8.232-1.329 1.873-3.387 2.81-6.174 2.81-2.831 0-4.933-.937-6.305-2.81-1.35-1.895
-2.025-4.639-2.025-8.232 0-3.593.675-6.337 2.025-8.232 1.372-1.916 3.474-2.874 6.305-2.874
zm419.08.109c2.76 0 4.799.938 6.114 2.814 1.337 1.876 2.005 4.603 2.005 8.183s-.668 6.296-2.005
8.15c-1.315 1.855-3.353 2.783-6.113 2.783-2.803 0-4.884-.928-6.243-2.782-1.337-1.876-2.005-4.593
-2.005-8.151s.668-6.275 2.005-8.151c1.359-1.898 3.44-2.846 6.243-2.846zm-174.25 13.423v5.595c-1.142
1.208-2.317 2.114-3.525 2.718-1.186.582-2.598.873-4.237.873-1.337 0-2.426-.302-3.267-.906-.82-.604
-1.229-1.606-1.229-3.008 0-.712.205-1.37.615-1.973.41-.604 1.089-1.132 2.037-1.585.95-.453 2.21-.82
3.785-1.1 1.574-.302 3.515-.506 5.822-.614zm120.173 4.884c-.69 0-1.337.13-1.941.388a4.894 4.894 0 0
0-1.552 1.035 4.895 4.895 0 0 0-1.036 1.553 5.173 5.173 0 0 0-.356 1.908c0 .69.119 1.337.356 1.94
a4.997 4.997 0 0 0 1.036 1.52c.43.432.948.767 1.552 1.004.604.259 1.25.388 1.94.388s1.327-.13 1.909
-.388a4.486 4.486 0 0 0 1.553-1.003 4.729 4.729 0 0 0 1.067-1.52c.259-.604.388-1.25.388-1.94 0-.67
-.13-1.306-.388-1.91a4.64 4.64 0 0 0-1.067-1.552 4.896 4.896 0 0 0-1.553-1.035 4.645 4.645 0 0 0
-1.908-.388zm-62.208 7.083c1.013.13 2.048.216 3.105.26 1.078.042 2.113.096 3.105.161 1.014.043
1.963.119 2.847.226.905.108 1.692.28 2.36.518.67.216 1.197.528 1.586.938.388.41.582.938.582 1.585s
-.162 1.24-.485 1.779c-.324.56-.841 1.035-1.553 1.423-.69.41-1.574.722-2.652.938-1.078.237-2.361.356
-3.85.356-1.466 0-2.705-.108-3.719-.324-1.013-.194-1.844-.474-2.49-.84-.626-.367-1.09-.81-1.391-1.327
a3.232 3.232 0 0 1-.42-1.617c0-.884.269-1.66.808-2.329.56-.668 1.283-1.25 2.167-1.747z"
                />
            </symbol>
        </svg>
    )
}
